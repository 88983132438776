import Vue from 'vue'
import VueRouter from 'vue-router'
import SideBar from '../components/general/Sidebar.vue'
import store from '@/store'

Vue.use(VueRouter)

// const isNotAuthenticated = (to, from, next) => {
//   // console.log('isNotAuthenticated', store.getters.isAuthenticated)
//   if (!store.state.authUser) {
//     next()
//     return
//   }
//   next('/')
// }

const isAuthenticated = (to, from, next) => {
  // console.log('isAuthenticated', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}


const routes = [
  {
    path: '/',
    redirect: '/accounts'
  },
  {
    path: '/login',
    name: 'LoginEmail',
    component: () => import(/* webpackChunkName: "auth" */ '../views/LoginEmail.vue')
  },
  {
    path: '/login/continue',
    name: 'LoginPassword',
    component: () => import(/* webpackChunkName: "auth" */ '../views/LoginPassword.vue'),
    props: true
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts',
    name: 'Accounts',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts/:orgId',
    redirect: '/accounts/:orgId/profile'
  },
  {
    path: '/accounts/:orgId/profile',
    name: 'AccountProfile',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Account/Profile.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts/:orgId/locations',
    name: 'AccountLocations',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Account/Locations.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts/:orgId/locations/:locationId',
    name: 'AccountLocationProfile',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Account/LocationProfile.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts/:orgId/staff',
    name: 'AccountStaff',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Account/Users.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts/:orgId/staff/create',
    name: 'AccountStaffCreate',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/User/Create.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/accounts/:orgId/staff/:userId',
    name: 'AccountStaffEdit',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/UserProfile.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/email/templates',
    name: 'EmailTemplates',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Email/Templates.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/email/profile',
    name: 'EmailProfile',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "accounts" */ '../views/Email/Profile.vue')
    },
    beforeEnter: isAuthenticated
  },  
  {
    path: '/email',
    redirect: '/email/templates'
  },
  {
    path: '/org/create/step1',
    name: 'OrgCreateStep1',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "org" */ '../views/Account/Create/Profile.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/org/create/step2',
    name: 'OrgCreateStep2',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "org" */ '../views/Account/Create/Locations.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/org/create/step3',
    name: 'OrgCreateStep3',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "org" */ '../views/Account/Create/Users.vue')
    },
    beforeEnter: isAuthenticated
  },
  {
    path: '/overview',
    name: 'Overview',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "overview" */ '../views/Overview/Index.vue')
    },
    beforeEnter: isAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
