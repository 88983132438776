<template>
      <v-navigation-drawer
        v-model="sidebar"
        color="white"
        :expand-on-hover="expandOnHover"
        :mini-variant="miniVariant"
        app
      >

        <v-list
          dense
          nav
          class="py-0"
        >
          <v-list-item two-line :class="miniVariant && 'px-0'">
            <v-list-item-content>
              <v-list-item-title style="color:#3B5998;text-align:center;font-size:24px;line-height:24px;">THENETWORKHUB</v-list-item-title>
              <v-list-item-subtitle class="text-left px-2">Dashboard</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item two-line :class="miniVariant && 'px-0'">
            
            <v-list-item-avatar>
              <!--<avatar :username="authUserName" color="#fff"  ></avatar>-->
              <a href="/profile">
                <v-avatar color="blue">
                  <span class="white--text">EZ</span>
                </v-avatar>
              </a>
            </v-list-item-avatar>

          <v-list-item-content>
            <!--<v-list-item-title class="text-left">Eli Z.</v-list-item-title>-->
            <v-list-item-subtitle class="text-left"><a @click.prevent="logout()">Logout</a></v-list-item-subtitle>
          </v-list-item-content>

          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            class="pt-2 pb-2 mb-0 sidebar--link"
            v-for="item in items"
            :key="item.title"
            link
            :to="item.href"
          >
          
            <v-list-item-icon>
              <v-icon class="sidebar--icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
          

            <v-list-item-content>
              <v-list-item-title class="text-left">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
                  <v-badge
                    v-if="item.badge"
                    :content="item.badge_content"
                    :value="item.badge_content"
                    color="orange"
                    inline
                  >
                  </v-badge>
          </v-list-item>

          <v-divider></v-divider>

          

          <v-list-item
            class="pt-2 pb-2 mb-0 sidebar--link"
            v-for="item in manage"
            :key="item.title"
            link
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon class="sidebar--icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-left">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>


          </v-list-item>


        </v-list>
      </v-navigation-drawer>
</template>

<script>
//import Avatar from 'vue-avatar'
import {mapGetters} from 'vuex'
export default {
  name: 'SideBar',

  components: {
    //Avatar
  },

  data:() => ({
    expandOnHover: false,
    miniVariant: false,
    drawer: true
  }),

  computed: {
    sidebar: {
      get() {
        return this.$store.state.app.sidebar
      },
      set(val) {
        this.$store.commit('TOGGLE_SIDEBAR', val)
      }
    },
    // ...mapState(['sidebar']),
    ...mapGetters(['openOrdersCount', 'readyOrdersCount', 'authUser', 'addedOrdersCount']),
    items() {
      return [
        { title: 'Overview', href: '/overview', icon: 'mdi-help-box', badge: false },
      ]
    },
    manage() {
      return [
        { title: 'Accounts', icon: 'mdi-clipboard-account-outline', href: '/accounts'},
        { title: 'Email Settings', icon: 'mdi-email-outline', href: '/email'},
      ]
    }
  },

  mounted() {
    const self = this
    this.$root.$on('toggleSidebar', function() {
      self.drawer = !self.drawer
    })
  },

  methods: {
    async logout() {
      await this.$store.dispatch('LOGOUT');
      this.$router.push('/login')
    }
  }
}
</script>