import BaseApiService from './base'

class AuthApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor () {
    super()
  }

  async login (user) {
    if (!user) throw new Error('Missing {user}')
    return new Promise((resolve, reject) => {
      this.axios({ url: process.env.VUE_APP_API_BASE_URL + '/api/login', data: user, method: 'POST', withCredentials: true  })
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }
}

export default AuthApiService
