

const state = {
  sidebar: true
}

const getters = {
  getSidebar: state => state.sidebar
}

const actions = {

}

const mutations = {
  TOGGLE_SIDEBAR: (state, val) => {
    state.sidebar = val
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
