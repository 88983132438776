import BaseApiService from './base'

class UserApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor () {
    super()
  }

  async list(orgId) {
    return new Promise((resolve, reject) => {
      this.axios.get('/organizations/'+orgId+'/users/list')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async profile(orgId, userId) {
    return new Promise((resolve, reject) => {
      this.axios.get('/organizations/'+orgId+'/users/'+userId+'/profile')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async update(orgId, user) {
    return new Promise((resolve, reject) => {
      this.axios.put('/organizations/'+orgId+'/users/'+user.id+'/update', user)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async checkUniqueEmail(data) {
    return new Promise((resolve, reject) => {
      this.axios.post('/users/checkEmail', data)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })    
  }

  async delete(id) {
    return new Promise((resolve, reject) => {
      this.axios.post('/users/delete/'+id)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })    
  }

  async create(orgId, data) {
    return new Promise((resolve, reject) => {
      this.axios.post('/users/create/'+orgId, data)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })    
  }
  
}

export default UserApiService
