import BaseApiService from './base'

class ReportApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor () {
    super()
  }

  async global(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/global', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async todayProcessed(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/todayProcessed', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async todayPickups(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/todayPickups', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async onhand(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/onhand', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async trends(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/trends', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async today(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/today', {...obj})
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async itemsWeekTrend(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/itemsWeekTrend', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async pickupsWeekTrend(obj) {
    return new Promise((resolve, reject) => {
      this.axios.post('/reports/pickupsWeekTrend', obj)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

}

export default ReportApiService
