import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Axios from './plugins/axios'
import './mixins/api'
import './sass/main.scss'
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import zoomlinedy from 'fusioncharts/fusioncharts.zoomline';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Charts from 'fusioncharts/fusioncharts.charts';

Vue.config.productionTip = false

// const token = window.localStorage.getItem('mm-dash-token')
// if (token) {
//   axios.defaults.headers.common.Authorization = token
// }

// if (process.env.NODE_ENV === 'local') {
// Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BASE_PATH
// } else {
//   Axios.defaults.baseURL = 'https://api.dash.test.thenetworkhub.net/v1'
// }



FusionCharts.options.license({
  // key: "YcC1orxB1D8B1D2F3F2F2I2C2D8C6C5B-11ffI3A1A7nA3B1B3cfqH2B7B2E-13yhE1A2E1suwC7E2B4B2H4I2D3B3D7E6F4hbbD2I3IA4rveE2F2D1G-7oB1E1D3D1eevA32B2B9D4A2F2A1G4D1E1B17B2D3A3l==",
    key: "8gG1kvdC8B2E6C1B2G2D2C1A2D7E6F4C-9bbD2I3B6rE3E2B2gjA-7B1B11B1A-13D-11lB4H3A33oqsD8B1B4F2D4D1J4A2A7C8A3tB-9C-8H2C2MA4C-16ziG2H3H1xkB8d2B4E6aarC3A5C6B2E2D1B1C4H1F3B2C8E7B2h==",
  creditLabel: false
});

Vue.use(VueFusionCharts, FusionCharts, Column2D, FusionTheme, Charts, zoomlinedy);


Vue.prototype.$http = Axios

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
