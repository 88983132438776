import axios from 'axios'

class BaseApiService {
  constructor () {
    this.axios = axios
  }

  handleErrors (e) {
    console.error(e)
    if (e.response) {
      return {
        code: e.response.status,
        message: e.response.data.message || ''
      }
    } else if (e.request) {
      return {
        code: 500,
        message: 'Network Error'
      }
    } else {
      return {
        code: 500,
        message: 'Unexpected error'
      }
    }
  }
}

export default BaseApiService
