import AuthApiService from './auth'
import OrganizationApiService from './organization'
import LocationApiService from './location'
import UserApiService from './user'
import EmailApiService from './email'
import ReportApiService from './report'

export const $api = {
  auth: new AuthApiService(),
  org: new OrganizationApiService(),
  location: new LocationApiService(),
  user: new UserApiService(),
  email: new EmailApiService(),
  report: new ReportApiService()
}
