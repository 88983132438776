import Axios from 'axios'

// Header 401: unauthenticated
import router from '../router'
import store from '../store'

Axios.defaults.withCredentials = true

const token = window.localStorage.getItem('mm-dash-token')
if (token) {
  Axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

// if (process.env.NODE_ENV === 'local') {
  Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_BASE_PATH
// } else {
  // Axios.defaults.baseURL = 'https://api.dash.test.thenetworkhub.net/v1'
// }

Axios.interceptors.response.use(response => response, async err => {
  const status = err.response.status
  console.log(status)

  if (status === 419) {
    // Refresh our session token
    await Axios.get(process.env.VUE_APP_API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true})

    // Return a new request using the original request's configuration
    return Axios(err.response.config)
  }

  if (status === 401) {
    // logout
    store.commit('auth_logout')
    router.push('/login')
  }

  return Promise.reject(err)
})

export default Axios