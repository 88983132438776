import Vuex from 'vuex'
import Vue from 'vue'
import apiPlugin from './plugins/api.plugin'
import auth from './modules/auth.module'
import org from './modules/org.module'
import app from './modules/app.module'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    org,
    app
  },
  plugins: [apiPlugin, createPersistedState()]
})
