import BaseApiService from './base'

class EmailApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor () {
    super()
  }

  async templates() {
    return new Promise((resolve, reject) => {
      this.axios.get('/email/templates')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async update(templates) {
    return new Promise((resolve, reject) => {
      this.axios.put('/email/templates/update', {templates})
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async profile() {
    return new Promise((resolve, reject) => {
      this.axios.get('/email/profile')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async updateProfile(profile) {
    return new Promise((resolve, reject) => {
      this.axios.put('/email/profile/update', {profile})
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  
}

export default EmailApiService
