// import axios from 'axios'

const newOrg = () => ({
  name: null,
  note: null,
  superadmin: {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    role: 'primary',
    all_access: true
  },
  files: [],
  locations: [],
  users: []
})

const state = {
  org: null
}

const getters = {

}

const actions = {

}

const mutations = {
  initNewOrg: (state)  => {
    state.org = newOrg()
  },
  updateOrg: (state, value) => {
    state.org = Object.assign(state.org, value)
  },
  updateSuperAdmin: (state, value) => {
    state.org.superadmin = Object.assign(state.org.superadmin, value)
  },
  updateFiles: (state, value) => {
    state.org.files = value
  },
  addLocation: (state, location) => {
    state.org.locations.push(location)
  },
  removeLocation: (state, location) => {
    const index = state.org.locations.indexOf(location)
    state.org.locations.splice(index, 1)
  },
  addStaff: (state, staff) => {
    state.org.users.push(staff)
  },
  removeStaff: (state, staff) => {
    const index = state.org.users.indexOf(staff)
    state.org.users.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}