import BaseApiService from './base'

class LocationApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor () {
    super()
  }

  async list(orgId) {
    return new Promise((resolve, reject) => {
      this.axios.get('/organizations/'+orgId+'/locations/list')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async create(orgId, location) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/'+orgId+'/locations/create', location)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async update(orgId, location) {
    return new Promise((resolve, reject) => {
      this.axios.put('/organizations/'+orgId+'/locations/'+location.id+'/update', location)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async all() {
    return new Promise((resolve, reject) => {
      this.axios.get('/locations')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async resendVerificationEmail(id) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/resendVerificationEmail/' + id)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })    
  }

  async deactivateAccounts(ids) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/deactivateAccounts', ids)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })    
  }

  async createAccount(id, account) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/'+id+'/accounts/create', account)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })    
  }

  async importCsv(id, formData) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/'+id+'/importCsv',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async lookupPhoneNumbers(data) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/lookupPhoneNumbers', data)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async cities(data) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/cities', data)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }  

  async profile(id) {
    return new Promise((resolve, reject) => {
      this.axios.get('/locations/' + id)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async delete(id) {
    return new Promise((resolve, reject) => {
      this.axios.post('/locations/delete/' + id)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }


}

export default LocationApiService
