import BaseApiService from './base'

class OrganizationApiService extends BaseApiService {
  // eslint-disable-next-line
  constructor () {
    super()
  }

  async list() {
    return new Promise((resolve, reject) => {
      this.axios.get('/organizations/list')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async profile(id) {
    return new Promise((resolve, reject) => {
      this.axios.get('/organizations/'+id+'/profile')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }
  
  async deactivate(id) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/'+id+'/deactivate')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async reactivate(id) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/'+id+'/reactivate')
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async update(id, org) {
    return new Promise((resolve, reject) => {
      this.axios.put('/organizations/'+id+'/update', org)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async upload(id, formData) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/'+id+'/files/upload', 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async deleteFiles(id, fileIds) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/'+id+'/files/delete', fileIds)
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async create(formData) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/create', 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }

  async updatePassword(currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.axios.post('/organizations/updatePassword', {currentPassword, newPassword})
        .then(response => {
          if (response.data.data) {
            resolve(response.data.data)
          } else {
            const unexpectedError = { code: 500, message: 'Unexpected Error' }
            reject(unexpectedError)
          }
        })
        .catch(e => {
          const error = this.handleErrors(e)
          reject(error)
        })
    })
  }


  
}

export default OrganizationApiService
