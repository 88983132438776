import axios from 'axios'
const state = {
  user: null,
  token: window.localStorage.getItem('mm-dash-token') || ''
}

const getters = {
  isAuthenticated: state => !!state.user,
  userFullName: state => !state.user ? '' : state.user.first_name + ' ' + state.user.last_name,
  authUser: state => state.user
}

const actions = {
  async login ({ commit }, user) {
    return new Promise((resolve, reject) => {
      this.$api.auth.login(user)
        .then(data => {
          console.log(data)
          const token = data.token
          const user = data.user
          window.localStorage.setItem('mm-dash-token', token)
          axios.defaults.headers.common.Authorization = `Bearer ${token}`
          commit('auth_success', { token, user })
          resolve('/')
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  async logout ({ commit }) {
    commit('auth_logout')
  },
}

const mutations = {
  auth_success: (state, data) => {
    state.user = data.user
    state.token = data.token
    window.localStorage.setItem('mm-dash-token', data.token)
  },
  auth_logout: (state) => {
    state.user = null
    state.token = null
    window.localStorage.setItem('mm-dash-token', null)
    delete axios.defaults.headers.common.Authorization
  },
  set_user: (state, user) => {
    state.user = Object.assign(state.user, user)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
